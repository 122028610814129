<template>
  <div class="block">
    <div>
      <strong>Week {{ week }} / {{ year }} </strong>
    </div>
    <div>
      <strong>{{ weekString }}</strong>
    </div>
    <div class="buttons">
      <div
        class="button is-light"
        @click="lastWeek()"
      >
        <strong>Last</strong>
      </div>
      <div
        class="button is-light"
        @click="nextWeek()"
      >
        <strong>Next</strong>
      </div>
      <div
        class="button is-light"
        @click="activateGoTo()"
      >
        <div v-if="goToBool">
          <input
            v-model="goTo"
            type="date"
          ><button @click="applyGoTo()">
            Go
          </button>
        </div>
        <div v-else>
          <strong>Go to</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);

export default {
  emits: ['update:date'],
  data() {
    return {
      date: '',
      startOfWeek: '',
      endOfWeek: '',
      week: '',
      year: '',
      weekString: '',
      goToBool: false,
      goTo: '',
    };
  },
  mounted() {
    this.date = dayjs();
    this.parseDate();
  },
  methods: {
    parseDate() {
      this.startOfWeek = this.date.startOf('isoWeek');
      this.endOfWeek = this.date.endOf('isoWeek');
      this.week = this.date.isoWeek();
      this.year = this.date.isoWeekYear();
      this.weekString = `${this.startOfWeek.format(
        'Do MMMM',
      )} - ${this.endOfWeek.format('Do MMMM')}`;
      this.$emit('update:date', [
        this.startOfWeek.format('YYYY-MM-DD'),
        this.endOfWeek.format('YYYY-MM-DD'),
        this.date.toDate(),
      ]);
    },
    lastWeek() {
      this.date = this.date.subtract(7, 'day');
      this.parseDate();
    },
    nextWeek() {
      this.date = this.date.add(7, 'day');
      this.parseDate();
    },
    activateGoTo() {
      this.goToBool = true;
    },
    applyGoTo() {
      this.date = dayjs(this.goTo);
      this.parseDate();
    },
  },
};
</script>

<style>
</style>
