import axios from './axios-wrapper';

function login(user) {
  const promise = axios.post('login', {
    email: user.email,
    password: user.password,
  });
  promise.then((response) => {
    if (response.data) {
      localStorage.setItem('token', JSON.stringify(response.data.token));
      localStorage.setItem('user_type', JSON.stringify(response.data.type));
      localStorage.setItem('associated_id', JSON.stringify(response.data.associated_id));
    }
  });
  return promise;
}

function logout() {
  return axios.post('logout').then(() => {
    localStorage.removeItem('token');
  });
}

export default { login, logout };
