import axioslib from 'axios';
import router from '../routes';

const axios = axioslib.create({
  baseURL: process.env.VUE_APP_API_URL,
});

axios.interceptors.response.use((response) => response, (error) => {
  if (error.response.status == 401) {
    localStorage.removeItem('token');
    if (error.response.config.url != 'notifications') {
      router.push('/login');
    }
  } else if (error.response.status == 404 && error.response.config.url != 'user') {
    router.push('/404');
  }
  return Promise.reject(error);
});

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    if (token == 'undefined') {
      localStorage.removeItem('token');
    } else {
      config.headers['x-authorization'] = JSON.parse(token); // eslint-disable-line no-param-reassign
    }
  }
  return config;
});

export default axios;
