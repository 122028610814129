import "bulma/css/bulma.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSort,
  faSortUp,
  faSortDown,
  faCheck,
  faTimes,
  faBell,
  faChevronLeft,
  faChevronDown,
  faAngleDown
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./routes";

library.add(faSort);
library.add(faSortUp);
library.add(faSortDown);
library.add(faCheck);
library.add(faTimes);
library.add(faBell);
library.add(faChevronLeft);
library.add(faChevronDown);
library.add(faAngleDown);

const app = createApp(App);

app.use(router);
app.component("FontAwesomeIcon", FontAwesomeIcon);
app.mount("#app");
