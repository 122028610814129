<template>
  <div>
    <!-- Create a dropdown for every filter name (Region, Designation, etc) -->
    <div
      class="dropdown"
      :class="{ 'is-active': filter_dropdown[filter_name] }"
      v-for="(filter_values, filter_name) in filters"
      :key="filter_name"
    >
      <div class="dropdown-trigger">
        <button
          class="button is-small"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          @click="toggleDropdown(filter_name)"
        >
          <span>{{ filter_name }}</span>
          <span class="icon is-small">
            <font-awesome-icon icon="angle-down" />
          </span>
        </button>
      </div>
      <div class="dropdown-menu" id="dropdown-menu" role="menu">
        <div class="dropdown-content" style="width: fit-content">
          <a
            class="dropdown-item"
            v-for="filter_value in filter_values"
            :key="filter_value"
          >
            <div style="display: flex; justify-content: space-between">
              <p style="padding-right: 2rem">{{ filter_value }}</p>
              <input
                type="checkbox"
                @click="toggleFilter(filter_name, filter_value)"
                v-model="this.checkbox_models[filter_name + filter_value]"
              />
            </div>
          </a>
        </div>
      </div>
    </div>
    <!-- End creating dropdowns -->
    <!-- Show all selected filters -->
    <div class="filterlist-container">
      <div
        v-for="(filter_values, filter_name) in selected_filters"
        :key="filter_values"
        class="filterlist-subcontainer"
      >
        <div
          v-for="filter_value in filter_values"
          :key="filter_value"
          class="filter-container"
        >
          <span class="tag">
            <div>{{ filter_value }}</div>
            <font-awesome-icon
              class="icon is-small"
              icon="times"
              @click="toggleFilter(filter_name, filter_value)"
              style="cursor: pointer"
            />
          </span>
        </div>
      </div>
    </div>
    <!-- End showing selected filters -->
  </div>
</template>

<script>
export default {
  props: ["filters", "init_selected"],
  emits: ["update:filters"],
  data() {
    return {
      filter_dropdown: {},
      selected_filters: {},
      checkbox_models: {},
    };
  },
  mounted() {
    if (this.init_selected) {
      for (const key_name of Object.keys(this.init_selected)) {
        for (const key_value of this.init_selected[key_name]) {
          this.toggleFilter(key_name, key_value, true);
        }
      }
      this.$emit("update:filters", this.selected_filters);
    }
  },
  methods: {
    toggleDropdown(filter_name) {
      // close all other dropdowns
      for (const key_name of Object.keys(this.filter_dropdown)) {
        if (key_name != filter_name) {
          this.filter_dropdown[key_name] = false;
        }
      }
      // toggle current dropdown
      if (filter_name in this.filter_dropdown) {
        this.filter_dropdown[filter_name] = !this.filter_dropdown[filter_name];
      } else {
        this.filter_dropdown[filter_name] = true;
      }
    },
    toggleFilter(filter_name, filter_value, init = false) {
      // toggle filter
      if (filter_name in this.selected_filters) {
        if (this.selected_filters[filter_name].includes(filter_value)) {
          const new_list = this.selected_filters[filter_name].filter(
            (e) => e !== filter_value
          ); // return list without filter_value
          if (new_list.length > 0) {
            this.selected_filters[filter_name] = new_list; // if there's still items in the array, update it
          } else {
            delete this.selected_filters[filter_name]; // if the array will be empty, remove the property from the object
          }
          this.checkbox_models[filter_name + filter_value] = false;
        } else {
          this.selected_filters[filter_name].push(filter_value);
          this.checkbox_models[filter_name + filter_value] = true;
        }
      } else {
        this.selected_filters[filter_name] = [filter_value];
        this.checkbox_models[filter_name + filter_value] = true;
      }
      if (!init) {
        this.$emit("update:filters", this.selected_filters);
      }
    },
  },
};
</script>

<style>
a.dropdown-item,
button.dropdown-item {
  padding-right: 1rem;
}

.filterlist-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.filterlist-subcontainer {
  display: flex;
  flex-direction: row;
}

.filter-container,
.dropdown {
  padding: 6px;
}
</style>
