<template>
  <div
    style="cursor: pointer"
    @click="setSort()"
  >
    {{ `${sortName[0].toUpperCase()}${sortName.slice(1)}` }}
    <font-awesome-icon
      v-if="sort == sortName && sortDir == 'asc'"
      icon="sort-up"
    />
    <font-awesome-icon
      v-else-if="sort == sortName && sortDir == 'desc'"
      icon="sort-down"
    />
    <font-awesome-icon
      v-else
      icon="sort"
    />
  </div>
</template>

<script>
export default {
  props: ['sortName', 'sort', 'sortDir'],
  emits: ['update:sort'],
  methods: {
    setSort() {
      if (this.sortName === this.sort) {
        this.emitSort(this.sortName, this.sortDir === 'asc' ? 'desc' : 'asc');
      } else {
        this.emitSort(this.sortName, 'asc');
      }
    },
    emitSort(sort, dir) {
      this.$emit('update:sort', [sort, dir]);
    },
  },
};
</script>

<style>
</style>
