<template>
  <tr
    style="cursor: pointer"
    @click="goToShift()"
  >
    <td>{{ day }}</td>
    <td>{{ date }}</td>
    <td>{{ start_time }}</td>
    <td>{{ end_time }}</td>
    <td v-if="shiftData.hours">
      {{ shiftData.hours }}
    </td>
    <td>{{ shiftData.type }}</td>
    <td>{{ shiftData.client_name }}</td>
    <td>{{ shiftData.location_name }}</td>
    <td>{{ shiftData.emp_f_name }} {{ shiftData.emp_l_name }}</td>
    <td v-if="manager">
      <font-awesome-icon
        v-if="shiftData.offers"
        icon="check"
      /><font-awesome-icon
        v-else
        icon="times"
      />
    </td>
    <td v-if="manager">
      <font-awesome-icon
        v-if="shiftData.responses"
        icon="check"
      /><font-awesome-icon
        v-else
        icon="times"
      />
    </td>
  </tr>
</template>

<script>
import dayjs from 'dayjs';
import { prettyTime } from '../../utils/time';

export default {
  props: ['shiftData', 'manager'],
  data() {
    return {
      date: '',
      day: '',
      start_time: '',
      end_time: '',
    };
  },
  mounted() {
    this.date = dayjs(this.shiftData.date);
    this.day = this.date.format('ddd');
    this.date = this.date.format('DD/MM/YYYY');
    this.start_time = prettyTime(this.shiftData.start_time);
    this.end_time = prettyTime(this.shiftData.end_time);
  },
  methods: {
    goToShift() {
      this.$router.push(`/shift/${this.shiftData.shift_id}`);
    },
  },
};
</script>

<style>
</style>
