<template>
  <div>
    <div class="columns">
      <div class="column is-5">
        <DateController @update:date="updateRange($event)" />
      </div>
      <div class="column is-4">
        <ShiftStats
          v-if="user_type != 2"
          :shifts="shifts"
        />
      </div>
    </div>
    <table
      class="table is-hoverable is-striped"
      style="width:fit-content;"
    >
      <thead>
        <tr>
          <th id="day">
            Day
          </th>
          <th id="date">
            <sort-block
              :sort-name="'date'"
              :sort="sort"
              :sort-dir="sortDir"
              @update:sort="updateSort($event)"
            />
          </th>
          <th id="start_time">
            Start
          </th>
          <th id="end_time">
            End
          </th>
          <th id="role">
            <sort-block
              :sort-name="'type'"
              :sort="sort"
              :sort-dir="sortDir"
              @update:sort="updateSort($event)"
            />
          </th>
          <th id="company">
            <sort-block
              :sort-name="'client'"
              :sort="sort"
              :sort-dir="sortDir"
              @update:sort="updateSort($event)"
            />
          </th>
          <th id="location">
            Location
          </th>
          <th id="employee">
            <sort-block
              :sort-name="'employee'"
              :sort="sort"
              :sort-dir="sortDir"
              @update:sort="updateSort($event)"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <TableRowShift
          v-for="shift in shifts"
          :key="shift.shift_id"
          :shift-data="shift"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import shiftApi from '../api/shifts';
import TableRowShift from './TableRows/TableRowShift.vue';
import DateController from './DateController.vue';
import ShiftStats from './ShiftStats.vue';
import SortBlock from './SortBlock.vue';

dayjs.extend(customParseFormat);

export default {
  name: 'Home',
  components: {
    TableRowShift,
    DateController,
    ShiftStats,
    SortBlock,
  },
  props: ['adminParams'],
  emits: ['date'],
  data() {
    return {
      startOfWeek: '',
      endOfWeek: '',
      shifts: [],
      sort: 'date',
      sortDir: 'asc',
      date: '',
    };
  },
  computed: {
    user_type() {
      return JSON.parse(localStorage.getItem('user_type'));
    },
    associated_id() {
      return JSON.parse(localStorage.getItem('associated_id'));
    },
  },
  watch: {
    date() {
      this.$emit('date', this.date);
    },
  },
  methods: {
    updateRange(event) {
      [this.startOfWeek, this.endOfWeek, this.date] = event;
      this.loadShifts();
    },
    loadShifts() {
      let params = {};
      switch (this.user_type) {
        case 1:
          params.employee_id = this.associated_id;
          break;
        case 2:
          params.client_id = this.associated_id;
          break;
        case 0:
        default:
          if (this.adminParams) {
            params = this.adminParams;
          }
          break;
      }
      shiftApi
        .getAllShifts(this.startOfWeek, this.endOfWeek, params)
        .then((response) => {
          this.shifts = response.data;
        })
        .then(() => {
          this.sortTable();
        });
    },
    setSort(s) {
      if (s === this.sort) {
        this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortDir = 'asc';
      }
      this.sort = s;
      this.sortTable();
    },
    sortTable() {
      const dir = this.sortDir === 'asc' ? 1 : -1;
      let func;
      switch (this.sort) {
        case 'type':
          func = (a, b) => a.type.localeCompare(b.type) * dir || this.dateTimeSort(a, b);
          break;
        case 'client':
          func = (a, b) => a.client_name.localeCompare(b.client_name) * dir
            || this.dateTimeSort(a, b);
          break;
        case 'employee':
          func = (a, b) => a.emp_f_name.localeCompare(b.emp_f_name) * dir
            || this.dateTimeSort(a, b);
          break;
        case 'date':
        default:
          func = (a, b) => dayjs(a.date).diff(dayjs(b.date)) * dir
            || a.start_time.localeCompare(b.start_time);
      }
      this.shifts.sort(func);
    },
    updateSort(sortEvent) {
      [this.sort, this.sortDir] = sortEvent;
      this.sortTable();
    },
    dateTimeSort(a, b) {
      return (
        dayjs(a.date).diff(dayjs(b.date))
        || a.start_time.localeCompare(b.start_time)
      );
    },
  },
};
</script>

<style>
</style>
