<template>
  <div
    class="dropdown"
    :class="{ 'is-active': isNotificationDropdownOpen }"
  >
    <div
      class="dropdown-trigger"
      style="cursor: pointer"
      @click="toggleDropdown()"
    >
      <font-awesome-icon
        icon="bell"
        :class="{ isActive: hasUnread }"
      />
    </div>
    <div class="dropdown-menu">
      <div class="dropdown-content">
        <div>
          <div v-if="notifications.length == 0">
            <div class="dropdown-item">
              <p>No notifications!</p>
            </div>
          </div>
          <div v-if="notifications.length > 1">
            <div class="dropdown-item">
              <p>
                Clear all notifications
                <font-awesome-icon
                  icon="times"
                  style="cursor: pointer"
                  @click="clearNotifications()"
                />
              </p>
            </div>
            <hr class="dropdown-divider">
          </div>
          <div
            v-for="(notif, index) in notifications"
            :key="notif.notification_id"
          >
            <div
              class="dropdown-item"
              style="cursor: pointer"
              @click="notifClicked(index)"
            >
              <p
                class="dropdown-note"
                :class="{ seen: notif.seen }"
              >
                {{ notif.text }}
              </p>
            </div>
            <hr
              v-if="index != notifications.length - 1"
              class="dropdown-divider"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import notificatonsApi from '../api/notifications';

export default {
  data() {
    return {
      isNotificationDropdownOpen: false,
      notifications: [],
    };
  },
  computed: {
    hasUnread() {
      return this.notifications.some((n) => n.seen == false);
    },
  },
  mounted() {
    this.getNotifications();
    setInterval(this.getNotifications, 300000); // check every 5 minutes
  },
  methods: {
    getNotifications() {
      notificatonsApi.getNotifications().then((response) => {
        this.notifications = response.data;
      });
    },
    toggleDropdown() {
      this.isNotificationDropdownOpen = !this.isNotificationDropdownOpen;
    },
    notifClicked(index) {
      const notif = this.notifications[index];
      this.isNotificationDropdownOpen = false;
      notif.seen = true;
      notificatonsApi.deleteNotification(notif.notification_id);
      if (notif.link) {
        this.$router.push(notif.link);
      }
    },
    clearNotifications() {
      this.toggleDropdown();
      for (const notif of this.notifications) {
        notif.seen = true;
        notificatonsApi.deleteNotification(notif.notification_id);
      }
    },
  },
};
</script>

<style>
.isActive {
  color: red;
}
.seen {
  color: lightslategrey;
}
.dropdown-note {
  white-space: pre-line;
}
</style>
