<template>
  <div
    v-if="user_type == 0 && isNotAtTop"
    class="button is-dark"
    @click="toTop()"
  >
    <strong>Back to top</strong>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isNotAtTop: false,
    };
  },
  computed: {
    user_type() {
      return JSON.parse(localStorage.getItem('user_type'));
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      this.isNotAtTop = window.scrollY != 0;
    },
    toTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style>
</style>
