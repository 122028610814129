<template>
  <div :id="style">
    <slot />
  </div>
</template>

<script>
/**
 * Wrapper for div.
 * Put page content in here and it will automatically move it according to stylesheet.
 *
 * How to:
 *  Put all page content inside PageContent tags.
 *
 * Props:
 *  type: the type of page to format to:
 *      options: default, form
 */
export default {
  props: {
    type: String,
  },
  computed: {
    style() {
      switch (this.type) {
        case 'form-wide':
          return 'content-card-form-wide';
        case 'form':
          return 'content-card-form';
        default:
          return 'content-card-main';
      }
    },
  },
};
</script>

<style>
#content-card-main {
  width: 75%;
  margin: auto;
  margin-top: 24px;
  padding: 10px;
  padding-bottom: 0px;
  justify-content: left;
  justify-items: left;
}
#content-card-form {
  width: fit-content;
  margin: auto;
  margin-top: 24px;
  padding: 10px;
  padding-bottom: 0px;
  justify-content: center;
  justify-items: center;
}
#content-card-form-wide {
  width: fit-content;
  margin: auto;
  margin-top: 24px;
  padding: 10px;
  padding-bottom: 0px;
}
#title {
  text-align: center;
}
</style>
