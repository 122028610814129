<template>
  <nav
    class="navbar container"
    role="navigation"
    aria-label="main navigation"
  >
    <div
      class="navbar-brand"
      style="padding: 10px; padding-top: 0px"
    >
      <img
        src="../assets/GNet.png"
        class="logo"
        alt="Logo"
      >
      <a
        role="button"
        class="navbar-burger"
        :class="{ 'is-active': isHamburgerOpen }"
        aria-label="menu"
        aria-expanded="false"
        data-target="navMenu"
        @click="openHamburgerMenu()"
      >
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </a>
    </div>
    <div
      id="navMenu"
      class="navbar-menu"
      :class="{ 'is-active': isHamburgerOpen }"
    >
      <div class="navbar-start">
        <router-link
          to="/"
          class="navbar-item"
        >
          Shifts
        </router-link>
        <router-link
          v-if="user_type == 1"
          to="/employee/availability"
          class="navbar-item"
        >
          Availability
        </router-link>
        <router-link
          v-if="user_type == 1"
          to="/employee/offers"
          class="navbar-item"
        >
          Shift offers
        </router-link>
        <router-link
          v-if="user_type == 0"
          to="/shift/manage"
          class="navbar-item"
        >
          Shift Manager
        </router-link>
        <router-link
          v-if="user_type == 0"
          to="/client"
          class="navbar-item"
        >
          Clients
        </router-link>
        <router-link
          v-if="user_type == 0"
          to="/employee"
          class="navbar-item"
        >
          Employees
        </router-link>
        <router-link
          v-if="user_type == 0 || user_type == 2"
          to="/report"
          class="navbar-item"
        >
          Reports
        </router-link>
      </div>
      <div class="navbar-end">
        <router-link
          v-if="user_type == 0"
          to="/admin"
          class="navbar-item"
        >
          Admin Panel
        </router-link>
        <router-link
          to="/account"
          class="navbar-item"
        >
          My Account
        </router-link>

        <div class="navbar-item">
          <notifications />
        </div>

        <div class="navbar-item">
          <div class="buttons">
            <a
              class="button is-dark"
              @click="logout()"
            >
              <strong>Log out</strong>
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import api from '../api/auth';
import Notifications from './Notifications.vue';

export default {
  name: 'Nav',
  components: {
    Notifications,
  },
  data() {
    return {
      isHamburgerOpen: false,
    };
  },
  computed: {
    user_type() {
      return JSON.parse(localStorage.getItem('user_type'));
    },
  },
  methods: {
    logout() {
      api.logout().then(() => {
        this.$router.push('/login');
      });
    },
    openHamburgerMenu() {
      this.isHamburgerOpen = !this.isHamburgerOpen;
    },
  },
};
</script>

<style>
a.router-link-active {
  color: #d88d00 !important;
}
nav {
  margin-top: 25px;
  margin-bottom: 30px;
}
a {
  font-weight: bold;
  color: #2c3e50;
}
.logo {
  padding-top: 10px;
  height: 45px !important;
  width: auto;
}
</style>
