<template>
  <Shifts />
</template>

<script>
import Shifts from './Shifts.vue';

export default {
  components: {
    Shifts,
  },
  computed: {
    user_type() {
      return JSON.parse(localStorage.getItem('user_type'));
    },
    associated_id() {
      return JSON.parse(localStorage.getItem('associated_id'));
    },
  },
};
</script>

<style>
</style>
