import axios from './axios-wrapper';

function getNotifications() {
  return axios.get('notifications');
}

function deleteNotification(id) {
  return axios.delete(`notification/${id}`);
}

function createNotificationUser(id, notification) {
  return axios.post(`notifications?user_id=${id}`, { notification });
}

function createNotificationUserType(user_type, notification) {
  return axios.post(`notifications?user_type=${user_type}`, { notification });
}

export default {
  getNotifications, deleteNotification, createNotificationUser, createNotificationUserType,
};
