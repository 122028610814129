<template>
  <div v-if="isAvailWeek">
    <table>
      <tr>
        <td class="legend" style=""><font-awesome-icon icon="times" /> - Unavailable</td>
        <td class="legend">? - Not set</td>
        <td class="cancelled legend">Cancelled</td>
        <td class="placed legend">Placed</td>
        <td class="offered legend">Offered</td>
        <td class="available legend">Available</td>
        <td class="sick legend">Sick</td>
      </tr>
    </table>
    <table class="table availability-table" :class="{ past: isPastWeek }">
      <tr>
        <th>
          <sort-block
            :sort-name="'employee'"
            :sort="sort"
            :sort-dir="sortDir"
            @update:sort="updateSort($event)"
          />
        </th>
        <th>
          <sort-block
            :sort-name="'type'"
            :sort="sort"
            :sort-dir="sortDir"
            @update:sort="updateSort($event)"
          />
        </th>
        <th>{{ getDayString("monday") }}</th>
        <th>{{ getDayString("tuesday") }}</th>
        <th>{{ getDayString("wednesday") }}</th>
        <th>{{ getDayString("thursday") }}</th>
        <th>{{ getDayString("friday") }}</th>
        <th>{{ getDayString("saturday") }}</th>
        <th>{{ getDayString("sunday") }}</th>
      </tr>
      <tr v-for="employee in selected_employees" :key="employee.employee_id">
        <td>{{ employee.first_name }} {{ employee.last_name }}</td>
        <td>{{ employee.designation }}</td>
        <td v-if="employee.monday" class="availability" :class="employee.monday.class">
          <strong v-if="employee.monday.str">
            {{ employee.monday.str }}
          </strong>
          <div v-else>
            <font-awesome-icon icon="times" />
          </div>
        </td>
        <td v-if="employee.tuesday" class="availability" :class="employee.tuesday.class">
          <strong v-if="employee.tuesday.str">
            {{ employee.tuesday.str }}
          </strong>
          <div v-else>
            <font-awesome-icon icon="times" />
          </div>
        </td>
        <td
          v-if="employee.wednesday"
          class="availability"
          :class="employee.wednesday.class"
        >
          <strong v-if="employee.wednesday.str">
            {{ employee.wednesday.str }}
          </strong>
          <div v-else>
            <font-awesome-icon icon="times" />
          </div>
        </td>
        <td
          v-if="employee.thursday"
          class="availability"
          :class="employee.thursday.class"
        >
          <strong v-if="employee.thursday.str">
            {{ employee.thursday.str }}
          </strong>
          <div v-else>
            <font-awesome-icon icon="times" />
          </div>
        </td>
        <td v-if="employee.friday" class="availability" :class="employee.friday.class">
          <strong v-if="employee.friday.str">
            {{ employee.friday.str }}
          </strong>
          <div v-else>
            <font-awesome-icon icon="times" />
          </div>
        </td>
        <td
          v-if="employee.saturday"
          class="availability"
          :class="employee.saturday.class"
        >
          <strong v-if="employee.saturday.str">
            {{ employee.saturday.str }}
          </strong>
          <div v-else>
            <font-awesome-icon icon="times" />
          </div>
        </td>
        <td v-if="employee.sunday" class="availability" :class="employee.sunday.class">
          <strong v-if="employee.sunday.str">
            {{ employee.sunday.str }}
          </strong>
          <div v-else>
            <font-awesome-icon icon="times" />
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { toRaw } from "vue";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import employeeApi from "../api/employee";
import shiftApi from "../api/shifts";
import offerApi from "../api/offer";
import SortBlock from "./SortBlock.vue";
import sickLeaveApi from "../api/sickleave";
import tagApi from "../api/tag";

dayjs.extend(isBetween);

export default {
  components: { SortBlock },
  props: ["dateRef", "filters"],
  data() {
    return {
      employees: [],
      shifts: [],
      shiftsPromise: "",
      date: "",
      sort: "employee",
      sortDir: "asc",
      cancellations: [],
      cancellationsPromise: "",
      offers: [],
      offersPromise: "",
      sickLeave: [],
      sickLeavePromise: "",
      selected_employees: [],
      employeePromise: "",
      employee_tags: {},
      tagPromise: "",
    };
  },
  computed: {
    isAvailWeek() {
      return dayjs(this.date).isBetween(
        dayjs().subtract(4, "week"),
        dayjs().add(7, "week"),
        "day",
        "[]"
      );
    },
    isPastWeek() {
      return dayjs(this.date).isBefore(dayjs(), "week");
    },
  },
  watch: {
    dateRef() {
      this.date = dayjs(this.dateRef);
      this.getAvailability();
    },
    filters: {
      async handler() {
        const filters = toRaw(this.filters);
        if (Object.keys(filters).length === 0) {
          this.selected_employees = this.employees;
        } else {
          this.selected_employees = [];
          await this.tagPromise;
          // eslint-disable-next-line
          for (const employee_number of Object.keys(this.employee_tags)) {
            let match_count = 0;
            for (const tag_name of Object.keys(filters)) {
              if (tag_name == "Type") {
                if (
                  this.employee_tags[employee_number][tag_name] &&
                  filters[tag_name].some((f) => this.employee_tags[employee_number][tag_name].some((e) => e.match(f)))
                ) {
                  match_count += 1;
                }
              } else {
                // eslint-disable-next-line
                if (
                  tag_name in this.employee_tags[employee_number] &&
                  this.employee_tags[employee_number][tag_name].some((e) =>
                    filters[tag_name].includes(e)) &&
                  this.employees.find((e) => e.employee_id == employee_number)
                ) {
                  match_count += 1;
                }
              }
            }
            if (match_count == Object.keys(filters).length) {
              this.selected_employees.push(
                this.employees.find((e) => e.employee_id == employee_number)
              );
            }
          }
        }
        this.sortTable();
      },
      deep: true,
    },
  },
  mounted() {
    this.date = dayjs(this.dateRef);
    this.getAvailability();
    this.tagPromise = tagApi.getAllEmployeeTags().then((response) => {
      this.employee_tags = response.data;
    });
  },
  methods: {
    updateEmployees() {},
    getAvailability() {
      this.date = dayjs(this.dateRef);
      this.employeePromise = employeeApi
        .getAllEmployeesWAvailability(this.date.isoWeek())
        .then((response) => {
          this.employees = response.data;
        })
        .then(() => {
          this.attachAvailability();
        });
      this.shiftsPromise = shiftApi
        .getAllShifts(
          this.date.startOf("isoWeek").format("YYYY-MM-DD"),
          this.date.endOf("isoWeek").format("YYYY-MM-DD"),
          {}
        )
        .then((response) => {
          this.shifts = response.data;
        });
      this.cancellationsPromise = shiftApi
        .getCancellations(
          this.date.startOf("isoWeek").format("YYYY-MM-DD"),
          this.date.endOf("isoWeek").format("YYYY-MM-DD")
        )
        .then((response) => {
          this.cancellations = response.data;
        });
      this.offersPromise = offerApi
        .getShiftOffers(
          this.date.startOf("isoWeek").format("YYYY-MM-DD"),
          this.date.endOf("isoWeek").format("YYYY-MM-DD")
        )
        .then((response) => {
          this.offers = response.data;
        });
      this.sickLeavePromise = sickLeaveApi
        .getSickLeave(
          this.date.startOf("isoWeek").format("YYYY-MM-DD"),
          this.date.endOf("isoWeek").format("YYYY-MM-DD")
        )
        .then((response) => {
          this.sickLeave = response.data;
        });
    },
    async attachAvailability() {
      await this.shiftsPromise;
      await this.cancellationsPromise;
      await this.offersPromise;
      this.parseAvailability();
    },
    parseAvailability() {
      for (const employee of this.employees) {
        for (const day of [
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
          "sunday",
        ]) {
          let str = this.availabilityToStr(employee[day]);
          const sick = this.isSick(employee.employee_id, this.getDate(day));
          const style = {
            available: false,
            placed: this.isPlaced(employee.employee_id, this.getDate(day)),
            cancelled: this.isCancelled(employee.employee_id, this.getDate(day)),
            offered: this.isOffered(employee.employee_id, this.getDate(day)),
            sick,
          };
          if (
            str != "" &&
            str != "?" &&
            !style.placed &&
            !style.cancelled &&
            !style.offered &&
            !style.sick
          ) {
            style.available = true;
          }
          if (sick) {
            str = "S";
          }
          employee[day] = {
            str,
            class: style,
          };
        }
      }
      this.selected_employees = this.employees;
    },
    dayToNum(day) {
      switch (day) {
        case "monday":
          return 0;
        case "tuesday":
          return 1;
        case "wednesday":
          return 2;
        case "thursday":
          return 3;
        case "friday":
          return 4;
        case "saturday":
          return 5;
        case "sunday":
          return 6;
        default:
          break;
      }
    },
    getDate(day) {
      return this.date
        .startOf("isoWeek")
        .add(this.dayToNum(day), "day")
        .format("YYYY-MM-DD");
    },
    availabilityToStr(availability) {
      let str = "";
      if (availability == null) {
        return "?";
      }
      if (availability.am && availability.pm && availability.night) {
        return "ANY";
      }
      if (availability.am) {
        str += "AM/";
      }
      if (availability.pm) {
        str += "PM/";
      }
      if (availability.night) {
        str += "NTS/";
      }
      return str.substring(0, str.length - 1);
    },
    isPlaced(employee_id, date) {
      return this.shifts.some(
        (e) => e.employee_id == employee_id && dayjs(e.date).format("YYYY-MM-DD") == date
      );
    },
    isCancelled(employee_id, date) {
      return this.cancellations.some(
        (e) => e.employee_id == employee_id && dayjs(e.date).format("YYYY-MM-DD") == date
      );
    },
    isOffered(employee_id, date) {
      return this.offers.some(
        (e) =>
          e.offered_employee == employee_id && dayjs(e.date).format("YYYY-MM-DD") == date
      );
    },
    isSick(employee_id, date) {
      const leave = this.sickLeave.filter((e) => e.employee_id == employee_id);
      if (leave.length == 0) {
        return false;
      }
      for (const record of leave) {
        if (
          dayjs(date).diff(record.start_date, "day") >= 0 &&
          dayjs(record.end_date).diff(date, "day") >= 0
        ) {
          return true;
        }
      }
      return false;
    },
    getDayString(day) {
      switch (day) {
        case "monday":
          return dayjs(this.date).startOf("isoWeek").format("ddd Do");
        case "tuesday":
          return dayjs(this.date).startOf("isoWeek").add(1, "day").format("ddd Do");
        case "wednesday":
          return dayjs(this.date).startOf("isoWeek").add(2, "day").format("ddd Do");
        case "thursday":
          return dayjs(this.date).startOf("isoWeek").add(3, "day").format("ddd Do");
        case "friday":
          return dayjs(this.date).startOf("isoWeek").add(4, "day").format("ddd Do");
        case "saturday":
          return dayjs(this.date).startOf("isoWeek").add(5, "day").format("ddd Do");
        case "sunday":
          return dayjs(this.date).endOf("isoWeek").format("ddd Do");
        default:
          break;
      }
    },
    updateSort(sortEvent) {
      [this.sort, this.sortDir] = sortEvent;
      this.sortTable();
    },
    sortTable() {
      const dir = this.sortDir === "asc" ? 1 : -1;
      let func;
      switch (this.sort) {
        case "type":
          func = (a, b) =>
            a.designation.localeCompare(b.designation) * dir ||
            a.first_name.localeCompare(b.first_name);
          break;
        case "employee":
        default:
          func = (a, b) => a.first_name.localeCompare(b.first_name) * dir;
          break;
      }
      this.selected_employees.sort(func);
    },
  },
};
</script>

<style>
.legend {
  padding: 5px;
  border: 1px solid;
  text-align: center !important;
}

.past {
  opacity: 70%;
}

.availability {
  text-align: center !important;
}

.sick {
  background-color: rgb(154, 88, 154);
}

.offered {
  background-color: rgb(255, 255, 95);
}

.placed {
  background-color: cornflowerblue;
}

.available {
  background-color: lightgreen;
}

.cancelled {
  background-color: lightcoral;
}
</style>
