<template>
  <div class="block">
    <div v-if="!detailed">
      <div>
        <strong>Total Shifts: {{ numShifts }}</strong><br>
        <strong>Total Hours: {{ numHours }}</strong>
      </div>
    </div>
    <div v-else>
      <div class="columns">
        <div class="column is-3">
          <table>
            <tr>
              <td />
              <th>Shifts by Type:</th>
            </tr>
            <tr>
              <th>RN:</th>
              <td>{{ typeDict.RN }}</td>
            </tr>
            <tr>
              <th>CG:</th>
              <td>{{ typeDict.CG }}</td>
            </tr>
            <tr>
              <th>CSW:</th>
              <td>{{ typeDict.CSW }}</td>
            </tr>
            <tr>
              <th>Admin:</th>
              <td>{{ typeDict.Admin }}</td>
            </tr>
            <tr>
              <th><br></th>
              <td />
            </tr>
            <tr>
              <th>Total Shifts:</th>
              <td>{{ numShifts }}</td>
            </tr>
            <tr>
              <th>Total Hours:</th>
              <td>{{ numHours }}</td>
            </tr>
          </table>
        </div>
        <div class="column is-5">
          <table>
            <tr>
              <td />
              <th>Shifts by Client:</th>
            </tr>
            <tr
              v-for="client in clientDict"
              :key="client"
            >
              <th>{{ client[0] }}</th>
              <td>{{ client[1] }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['shifts', 'detailed'],
  data() {
    return {
      numHours: 0,
      numShifts: 0,
      typeDict: {
        RN: 0, CG: 0, CSW: 0, Admin: 0,
      },
      clientDict: {},
    };
  },
  watch: {
    shifts() {
      this.clear();
      this.update();
    },
  },
  mounted() {
    this.update();
  },
  methods: {
    clear() {
      this.numHours = 0;
      this.numShifts = 0;
      this.typeDict = {
        RN: 0, CG: 0, CSW: 0, Admin: 0,
      };
      this.clientDict = {};
    },
    update() {
      this.numShifts = this.shifts.length;
      this.getStats();
    },
    getStats() {
      let hours = 0;
      let mins = 0;
      for (const shift of this.shifts) {
        const t1 = shift.start_time.split('T')[0].split(':');
        const t2 = shift.end_time.split('T')[0].split(':');
        const diff = this.timeDiff(t1[0], t1[1], t2[0], t2[1]);
        hours += diff[0];
        mins += diff[1];

        this.typeDict[shift.type] += 1;
        if (this.clientDict[shift.client_name]) {
          this.clientDict[shift.client_name] += 1;
        } else {
          this.clientDict[shift.client_name] = 1;
        }
      }
      this.clientDict = Object.entries(this.clientDict);
      this.numHours = (hours + mins / 60).toFixed(2);
    },
    timeDiff(t1h, t1m, t2h, t2m) {
      let m = t2m - t1m;
      let h = t2h - t1h;
      if (h < 0) {
        h += 24;
      }
      if (m < 0) {
        h -= 1;
        m += 60;
      }
      return [h, m];
    },
  },
};
</script>

<style>
th {
    min-width: 90px;
}
td {
  padding-left: 5px !important;
}
</style>
