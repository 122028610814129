import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/Home.vue';
import Login from './views/Login.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/shift/:id',
    name: 'Shift',
    component: () => import('./views/Shift.vue'),
  },
  {
    path: '/shift/add',
    name: 'Shift Adder',
    component: () => import('./views/ShiftAdd.vue'),
  },
  {
    path: '/shift/edit/:id',
    name: 'Shift Editor',
    component: () => import('./views/ShiftEdit.vue'),
  },
  {
    path: '/client',
    name: 'Clients',
    component: () => import('./views/Clients.vue'),
  },
  {
    path: '/client/:id',
    name: 'Client',
    component: () => import('./views/Client.vue'),
  },
  {
    path: '/client/edit/:id',
    name: 'Client Editor',
    component: () => import('./views/ClientEdit.vue'),
  },
  {
    path: '/client/add',
    name: 'Client Adder',
    component: () => import('./views/ClientAdd.vue'),
  },
  {
    path: '/employee',
    name: 'Employees',
    component: () => import('./views/Employees.vue'),
  },
  {
    path: '/employee/:id',
    name: 'Employee',
    component: () => import('./views/Employee.vue'),
  },
  {
    path: '/employee/edit/:id',
    name: 'Employee Editor',
    component: () => import('./views/EmployeeEdit.vue'),
  },
  {
    path: '/employee/add',
    name: 'Employee Adder',
    component: () => import('./views/EmployeeAdd.vue'),
  },
  {
    path: '/user/:id',
    name: 'User',
    component: () => import('./views/User.vue'),
  },
  {
    path: '/user/edit/:id',
    name: 'User Editor',
    component: () => import('./views/UserEdit.vue'),
  },
  {
    path: '/user/add',
    name: 'User Adder',
    component: () => import('./views/UserAdd.vue'),
  },
  {
    path: '/account',
    name: 'My Account',
    component: () => import('./views/Account.vue'),
  },
  {
    path: '/account/reset',
    name: 'Password Reset',
    component: () => import('./views/ResetPassword.vue'),
  },
  {
    path: '/report',
    name: 'Reports',
    component: () => import('./views/Reporting.vue'),
  },
  {
    path: '/employee/availability',
    name: 'Availability',
    component: () => import('./views/EmployeeAvailability.vue'),
  },
  {
    path: '/shift/manage',
    name: 'Shift Manager',
    component: () => import('./views/ShiftManager.vue'),
  },
  {
    path: '/admin',
    name: 'Admin Panel',
    component: () => import('./views/AdminPanel.vue'),
  },
  {
    path: '/employee/offers',
    name: 'Shift Offers',
    component: () => import('./views/EmployeeOffer.vue'),
  },
  {
    path: '/404',
    name: 'Error 404',
    component: () => import('./views/404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to) => {
  document.title = `Gaia Net - ${to.name}`;
});

export default router;
