<template>
  <PageContent type="form">
    <div
      v-if="!forgotPassword"
      class="card"
    >
      <div class="card-content">
        <div class="block login-block">
          <img
            src="../assets/GNet.png"
            alt="Logo"
            style="width: 200px; height: auto !important"
          >
        </div>
        <form class="form-group">
          <div class="block login-block">
            <table>
              <tr>
                <input
                  v-model="input.email"
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                  @keyup.enter="login()"
                >
              </tr>
              <tr>
                <input
                  v-model="input.password"
                  type="password"
                  name="password"
                  placeholder="Password"
                  required
                  @keyup.enter="login()"
                >
              </tr>
            </table>
          </div>
          <div
            v-if="error != ''"
            class="block login-block"
          >
            <p>{{ error }}</p>
          </div>
          <div class="block login-block">
            <div class="buttons">
              <table>
                <tr>
                  <a
                    class="button is-dark is-small"
                    @click="login()"
                  >
                    <strong>Login</strong>
                  </a>
                </tr>
                <tr>
                  <a
                    class="button is-light is-small"
                    @click="swapForgotPassword()"
                  >
                    <strong>Forgot your password?</strong>
                  </a>
                </tr>
              </table>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div
      v-if="forgotPassword"
      class="card"
    >
      <div class="card-content">
        <p class="title">
          Forgot password
        </p>
        <form class="form-group">
          <input
            v-model="input.email"
            type="email"
            name="email"
            placeholder="Email"
            required
            @keyup.enter="login()"
          >
          <div
            v-if="error != ''"
            class="block login-block"
          >
            <p>{{ error }}</p>
          </div>
          <div class="buttons">
            <a
              class="button is-dark is-small"
              @click="sendResetEmail()"
            >
              <strong>Send reset email</strong>
            </a>
            <a
              class="button is-light is-small"
              @click="swapForgotPassword()"
            >
              <strong>Cancel</strong>
            </a>
          </div>
        </form>
      </div>
    </div>
  </PageContent>
</template>

<script>
import PageContent from '../components/PageContent.vue';
import authApi from '../api/auth';
import emailApi from '../api/email';

export default {
  name: 'Login',
  components: {
    PageContent,
  },
  data() {
    return {
      input: {
        email: '',
        password: '',
      },
      error: '',
      forgotPassword: false,
    };
  },
  methods: {
    login() {
      authApi
        .login(this.input)
        .then(() => {
          this.$router.push('/');
        })
        .catch((err) => {
          if (err.response) {
            this.error = err.response.data;
          }
        });
    },
    swapForgotPassword() {
      this.forgotPassword = !this.forgotPassword;
      this.error = '';
    },
    sendResetEmail() {
      emailApi
        .sendReset(this.input.email)
        .then((response) => {
          if (response.status == 200) {
            this.error = 'Reset email sent, please allow 5 minutes for the email to reach your inbox';
          }
        })
        .catch((err) => {
          if (err.response) {
            this.error = err.response.data.message;
          }
        });
    },
  },
};
</script>

<style>
.login-block {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
