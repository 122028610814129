import dayjs from "dayjs";
import axios from "./axios-wrapper";

function saveSickLeave(employee_id, leave) {
  return axios.post(`/sick_leave/${employee_id}`, leave);
}

function getEmployeeSickLeave(employee_id) {
  return axios.get(`/sick_leave/${employee_id}`);
}

function getSickLeave(from, to) {
  return axios.get(`/sick_leave/${from}/${to}`);
}

function deleteEmployeeSickLeave(employee_id, leave) {
  return axios.delete(
    `/sick_leave/${employee_id}?start=${dayjs(leave.start_date).format(
      "YYYY-MM-DD"
    )}&end=${dayjs(leave.end_date).format("YYYY-MM-DD")}`,
    leave
  );
}

export default {
  saveSickLeave,
  getEmployeeSickLeave,
  getSickLeave,
  deleteEmployeeSickLeave,
};
