import axios from './axios-wrapper';

function getShiftOffersAndResponses(shift_id) {
  return axios.get(`shift/offers/${shift_id}`);
}

function createShiftOffer(shift_id, employees) {
  return axios.post('/shift/offers', { offer: { shift_id, employees } });
}

function getOfferedShiftsEmployee(employee_id) {
  return axios.get(`/shift/offers/employee/${employee_id}`);
}

function respondToShiftOffer(shift_id, response) {
  return axios.post(`/shift/offers/${shift_id}`, { response });
}

function getEmployeeOffersAndResponses(employee_id) {
  return axios.get(`/shift/offers/employee/${employee_id}?responses=1`);
}

function getShiftOffers(from, to) {
  return axios.get(`/shift/offers/${from}/${to}`);
}

export default {
  getShiftOffersAndResponses, getShiftOffers, createShiftOffer, getOfferedShiftsEmployee, respondToShiftOffer, getEmployeeOffersAndResponses,
};
