import axios from './axios-wrapper';

function sendReset(email) {
  return axios.post(`email/reset?email=${email}`);
}

function getEmailSettings() {
  return axios.get('email/settings');
}

function setEmailSettings(settings) {
  return axios.post('email/settings', { email_settings: settings });
}

export default { sendReset, getEmailSettings, setEmailSettings };
