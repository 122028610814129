import axios from './axios-wrapper';

function getAllShifts(from, to, params) {
  return axios.get(`shifts/${from}/${to}`, { params });
}

function getFillingShifts() {
  return axios.get('shifts/filling');
}

function getFillingShiftsBetween(from, to) {
  return axios.get(`shifts/filling?from=${from}&to=${to}`);
}

function getShift(id) {
  return axios.get(`shift/${id}`);
}

function createShifts(shifts) {
  return axios.post('shift/create', { shifts });
}

function importShifts(input) {
  return axios.post('shift/import', { shifts: input });
}

function deleteShift(id) {
  return axios.delete(`shift/delete/${id}`);
}

function editShift(id, shift) {
  return axios.put(`shift/edit/${id}`, { shift });
}

function cancelShift(id, cancellation) {
  return axios.post(`shift/cancel/${id}`, { cancellation });
}
function getCancellations(from, to) {
  return axios.get(`shift/cancellations/${from}/${to}`);
}

function getShiftCancellations(shift_id) {
  return axios.get(`shift/cancellations/${shift_id}`);
}

function undoCancellation(shift_id) {
  return axios.delete(`shift/cancellations/${shift_id}`);
}

function deleteOffer(shift_id, employee_id) {
  return axios.delete(`/shift/offers?shift_id=${shift_id}&employee_id=${employee_id}`);
}

export default {
  getAllShifts, getShift, createShifts, importShifts, deleteShift, editShift, getFillingShifts, getFillingShiftsBetween, cancelShift, getCancellations, undoCancellation, deleteOffer, getShiftCancellations
};
