import axios from './axios-wrapper';

function getTags() {
  return axios.get(`tag`);
}

function getAllEmployeeTags() {
  return axios.get(`tag/employee`);
}

function getEmployeeTags(employee_id) {
  return axios.get(`tag/employee`, { params: { employee_id } });
}

function saveEmployeeTags(employee_id, tags) {
  return axios.post(`tag/employee/${employee_id}`, tags);
}

function getTagsWId() {
  return axios.get(`tag`, { params: { include_id: true } });
}

export default {
  getTags, getAllEmployeeTags, getEmployeeTags, saveEmployeeTags, getTagsWId
};
