<template>
  <div id="app">
    <div v-if="path !== '/login' && path !== '/account/reset'">
      <Nav />
    </div>
    <div id="view">
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>
<script>
import { useRoute } from 'vue-router';
import Nav from './components/Nav.vue';

export default {
  name: 'App',
  components: {
    Nav,
  },
  computed: {
    path() {
      return useRoute().path;
    },
  },
};
</script>
<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
#view {
  margin-left: 30px;
  margin-top: 20px;
  align-content: left;
}
nav {
  margin-top: 25px;
  margin-bottom: 30px;
}

th {
  width: fit-content;
  min-width: 0px;
}

</style>
