<template>
  <div class="home">
    <div class="columns">
      <div class="column">
        <shift-table @date="($event) => {date = $event}" />
        <to-top-button />
      </div>
      <div class="column">
        <FilterBlock  v-if="user_type == 0" :filters="this.tags" @update:filters="update_filters($event)"/>
        <availability-table
          v-if="user_type == 0 && date"
          :date-ref="date"
          :filters="selected_filters"
        />
      </div>
    </div>
  </div>
</template>
<script>
import AvailabilityTable from '../components/AvailabilityTable.vue';
import ToTopButton from '../components/ToTopButton.vue';
import ShiftTable from '../components/ShiftTable.vue';
import FilterBlock from '../components/FilterBlock.vue';
import tagApi from "../api/tag";

export default {
  name: 'Home',
  components: {
    AvailabilityTable,
    ToTopButton,
    ShiftTable,
    FilterBlock
  },
  data() {
    return {
      date: '',
      tags: {},
      selected_filters: {}
    };
  },
  computed: {
    user_type() {
      return JSON.parse(localStorage.getItem('user_type'));
    },
  },
  mounted() {
    tagApi.getTags().then((response) => {
      const tags = response.data;
      tags.Type = ["CG", "CSW", "EN", "RN"];
      this.tags = tags;
    });
  },
  methods: {
    update_filters(event) {
      this.selected_filters = event;
    }
  }
};
</script>

<style>
.float-right {
  margin-right: 0px;
  margin-left: auto;
}
</style>
