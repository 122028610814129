import dayjs from 'dayjs';
import axios from './axios-wrapper';

function getEmployees() {
  return axios.get('employees');
}

function getActiveEmployees() {
  return axios.get('employees?active=1');
}

function getInactiveEmployees() {
  return axios.get('employees?active=0');
}

function getEmployeesByAvailability(date, start_time) {
  return axios.get(`employees?date=${dayjs(date).format('YYYY-MM-DD')}&time=${start_time}`);
}

function getEmployeesByDesignation(designation) {
  return axios.get(`employees?designation=${designation}`);
}

function getEmployeesByDesignationAndAvailability(designation, date, start_time) {
  return axios.get(`employees?designation=${designation}&date=${dayjs(date).format('YYYY-MM-DD')}&time=${start_time}`);
}

function getEmployee(id) {
  return axios.get(`employee/${id}`);
}

function editEmployee(id, details) {
  return axios.put(`employee/${id}`, { info: details });
}

function editEmployeeStatus(employee_id, status) {
  return axios.post(`employee/${employee_id}/status?status=${status}`);
}

function getActiveNonStatusEmployees() {
  return axios.get('employees?active=1&nonEmployee=0');
}

function addEmployee(employee) {
  return axios.post('employee/add', { employee });
}

function deleteEmployee(id) {
  return axios.delete(`employee/${id}`);
}

function saveAvailability(id, wkNum, availability) {
  return axios.post(`employee/availability/${id}?week=${wkNum}`, availability);
}

function getAvailability(id, wkNum) {
  return axios.get(`employee/availability/${id}?week=${wkNum}`);
}

function getAvailabilityDay(id, wkNum, day) {
  return axios.get(`employee/availability/${id}?week=${wkNum}&day=${day}`);
}

function setShiftEmployee(id, employee_id) {
  return axios.put(`shift/edit/employee/${id}?employee_id=${employee_id}`);
}

function getAllEmployeesWAvailability(wkNum) {
  return axios.get(`employees?weekNum=${wkNum}`);
}

function getAllEmployeeAvailabilityDay(date) {
  return axios.get(`employees/availability?date=${date}`);
}

function getCancelledEmployees(date) {
  return axios.get(`employees?cancelled=true&date=${date}`);
}

function getEmployeeCancellations(employee_id) {
  return axios.get(`employee/${employee_id}?cancellations=1`);
}

export default {
  setShiftEmployee, editEmployeeStatus, getEmployeeCancellations, getAllEmployeeAvailabilityDay, getAllEmployeesWAvailability, getEmployees, getActiveEmployees, getEmployee, editEmployee, addEmployee, deleteEmployee, getEmployeesByDesignationAndAvailability, getInactiveEmployees, getEmployeesByAvailability, getEmployeesByDesignation, saveAvailability, getAvailability, getAvailabilityDay, getActiveNonStatusEmployees, getCancelledEmployees,
};
